import {AfterViewInit, Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { OpenIDConnectAuthService } from "services/core/authentication/oidc-auth.service";
import {NgForm} from "@angular/forms";
import {UUID} from "angular2-uuid";

@Component({
    selector: "login-component",
    templateUrl: "./login.component.html",
})
export class LoginComponent implements AfterViewInit {
    @ViewChild("loginForm") loginForm: NgForm;
    @ViewChild("username") usernameField: ElementRef;
    @ViewChild("password") passwordField: ElementRef;
    @ViewChild("g2FA") g2FAField: ElementRef;

    @Input() redirectUrl?: string;
    otherLoginButton: { style: string, text: string } = null;
    loginDisabled: boolean = false;
    needs2FA: boolean = false;
    errorMessage: string;
    isLoginInProgress: boolean = false;

    private clientId: number = 0;

    constructor(private oidcAuthService: OpenIDConnectAuthService,
                private http: HttpClient, @Inject('window') private window: Window)
    {
        this.loadLoginSettings();
        if (this.isOIDCAuthenticationInProgress()) {
            this.isLoginInProgress = true;
        }

        if (!this.redirectUrl) {
            this.redirectUrl = this.getRedirectUrlFromLocation();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.tryAutoFillUsername()) {
                this.passwordField.nativeElement.focus();
            } else {
                this.usernameField.nativeElement.focus();
            }
        });
    }

    private getRedirectUrlFromLocation() {
        if (this.window.location.search.length < 2) {
            return;
        }

        const backUrlRegexp = /backUrl=(.*)/g;
        const fragmentValue = this.window.location.search.substring(1);
        const fragments = fragmentValue.split('&');
        for (let fragment of fragments) {
            const backUrl = backUrlRegexp.exec(fragment)[1];
            if (backUrl) {
                return decodeURIComponent(backUrl);
            }
        }
    }

    private tryAutoFillUsername() {
        if (this.window.location.hash.length < 2) {
            return false;
        }

        const usernameRegexp = /user=(.*)/g;
        const fragmentValue = this.window.location.hash.substring(1);
        const fragments = fragmentValue.split('&');
        for (let fragment of fragments) {
            const username = usernameRegexp.exec(fragment)[1];
            if (username) {
                this.loginForm.setValue({ username: username, password: '', g2FA: '' });
                return true;
            }
        }

        return false;
    }

    private loadLoginSettings() {
        let url = "/login/settings";
        let pathTokens = this.window.location.pathname.split("/");
        if (pathTokens.length === 3 && pathTokens[2] !== "index") {
            url = "/login/" + pathTokens[2] + "/settings";
        }

        this.http.get(url).subscribe(
            response => {
                this.clientId = response["content"].id;

                const oidc = response["content"].oidc;
                if (oidc?.enabled) {
                    this.setupOIDC(oidc);
                }
            },
            () => {
                this.errorMessage = "Klient neexistuje."
                this.loginDisabled = true
            }
        );
    }

    private setupOIDC(oidcSettings) {
        this.otherLoginButton = {
            style: oidcSettings.loginButtonStyle || "link-button",
            text: oidcSettings.loginButtonText || "Jiné možnosti přihlásení"
        }

        this.oidcAuthService.configure(oidcSettings.authConfig).then(() => {
            if (this.isOIDCAuthenticationInProgress()) {
                this.oidcAuthService.authenticate().then(() => {
                    if (this.oidcAuthService.isAuthenticated()) {
                        this.finalizeOIDCAuthentication()
                    }
                });
            }
        })
    }

    g2FAChanged() {
        if (this.loginForm.value.g2FA?.length == 6) {
            this.login();
        }
    }

    login() {
        if (!this.loginForm.valid) {
            return;
        }

        if (!this.checkUsername(this.loginForm.value.username)) {
            this.errorMessage = "Jméno obsahuje nepovolené znaky.";
            return;
        }

        if (!LoginComponent.checkPassword(this.loginForm.value.password)) {
            this.errorMessage = "Heslo nesmí obsahovat mezery.";
            return;
        }

        this.loginDisabled = true;
        this.http.post("/login", this.getLoginData()).subscribe(
            response => this.handleLoginResponse(response["content"]),
            errResponse => {
                console.error("Failed to login due to an unexpected error", errResponse);
                this.loginDisabled = false;
            }
        );
    }

    private handleLoginResponse(response: any) {
        if (response.isLogged) {
            this.needs2FA = false;
            this.errorMessage = null;
            this.redirectToMainPage();
            return;
        }

        if (response.g2FA) {
            this.needs2FA = true;
            this.g2FAField.nativeElement.focus();
        }

        if (response.message) {
            this.errorMessage = response.message;
        }

        this.loginDisabled = false;
    }

    private redirectToMainPage() {
        this.window.location.href = LoginComponent.getMainURL() + (this.redirectUrl?.length ? this.redirectUrl : "/") + "?lft=" + UUID.UUID();
    }

    private static getMainURL(): string {
        return document.location.protocol + "//" + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
    }

    isOIDCAuthenticationInProgress(): boolean {
        return this.window.location.search.indexOf("code=") > 0 && this.window.location.search.indexOf("state=") > 0;
    }

    async initiateOIDCAuthentication() {
        if (this.oidcAuthService.isAuthenticated()) {
            this.finalizeOIDCAuthentication();
            return;
        }

        if (!this.oidcAuthService.isAuthenticated() && this.oidcAuthService.hasAuthToken()) {
            await this.oidcAuthService.removeTokens(true);
        }

        await this.oidcAuthService.authenticate();
    }

    private finalizeOIDCAuthentication() {
        if (!this.oidcAuthService.hasAuthToken()) {
            return;
        }

        // Exchange OIDC access token for cookies
        // This should redirects us to the main ETIS page (with proper security cookies set) if the request is successful
        this.http.post("/login/oidc", { token: this.oidcAuthService.getAuthToken() }).subscribe(
            () => {
                this.oidcAuthService.removeTokens(true);
                this.redirectToMainPage();
            },
            () => {
                this.errorMessage = "Nelze ověřit identitu uživatele.";
                this.isLoginInProgress = false;
            }
        );
    }

    private checkUsername(username: string): boolean {
        if (username) {
            return ![" ", ";", "=", "(", ")"].some(char => username.indexOf(char) > -1);
        }

        return false;
    }

    private static checkPassword(password: string): boolean {
        if (password) {
            return password.indexOf(" ") == -1;
        }

        return false;
    }

    private getLoginData() {
        let data = {
            login: {
                email: this.loginForm.value.username,
                password: this.loginForm.value.password
            },
            g2FA: this.loginForm.value.g2FA
        };

        if (this.clientId > 0) {
            data["clientId"] = this.clientId;
        }

        return data;
    }
}
